
import store from "@/store/index";
import { DepartmentController } from '@/controller';
import { Button, IconButton } from '@/ui/index';
import { defineComponent } from 'vue';

export default defineComponent({
    name: "DepartmentForm",
    components: { Button, IconButton },
    data() {
        return{
            store,
            editMode: false,
            form:{
                uid: 0,
                title: "",
                number: ""
            }
        }
    },
    created() {
        if (this.$route.params.id && this.$route.params.id != "0" ) {
            this.editMode = true;
            this.handleGetDepartment(this.$route.params.id);
        } else {
            this.editMode = false;
        }
    },
    methods:{
        handleGoBack() {
            this.$router.go(-1);
        },
        async handleGetDepartment(uid:any) {
            const res = await DepartmentController.getDepartmentById(uid);
            if (!res.error) {
                this.form = res.department;
            } else {
                this.$notify(this.$t(res.error.message), { position: "right", type: "error" });
            }
        },
        handleSubmitPrevent(){
            if (this.editMode) {
                this.handleSave();
            } else {
                this.handleCreate();
            }
        },
        async handleCreate() {
            const res = await DepartmentController.createDepartment(this.form);
            if (!res.error) {
                this.$notify(this.$t("success.departmentCreated"), { position: "top" });
                this.$router.push("/administration/department/detail/" + res.department.uid);
            } else {
                this.$notify(this.$t(res.error.message), { position: "right", type: "error" });
            }
        },
        async handleSave() {
            const res = await DepartmentController.updateDepartment(this.form);
            if (!res.error) {
                this.form = res.department;
                this.$notify(this.$t("success.departmentUpdated"), { position: "top" });
                this.$router.go(-1);
            } else {
                this.$notify(this.$t(res.error.message), { position: "right", type: "error" });
            }
        }
    }
})
